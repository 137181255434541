<template>
    <div class="statistic-block" >
        <div class="df df-space-between">
            <div class="text-size-xxl text-bold df">
                <span>Продажи ремонтов</span>
            </div>
            <div class="year-select ml24">
                <div class="arrow left" @click="currentYearIndex - 1 < 0 ? currentYearIndex = 0  : currentYearIndex = currentYearIndex - 1;"></div>
                <div class="text-size-xxl text-bold text-center year">
                    {{ yearsSale[currentYearIndex]}}
                </div>
                <div class="arrow right"  :class="{'opacity-0': currentYearIndex+1 == yearsSale.length}" @click="currentYearIndex + 1 < yearsSale.length ? currentYearIndex += 1 : currentYearIndex;"></div>
            </div>
            <tumbler class="free-requests-tumbler" :options="optionsTeam" :elem-width="61" v-model="form.optionRepairSale.value"/>
            <div class="text-size-xxl text-bold">
                {{ totalTeam.total }}
            </div>
        </div>
        <div class="graph graph-repairs-sale" v-if="totalTeam.percent">
            <div class="graph-item">
                <div class="graph-item__name">
                    Продажи ремонтов
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{totalTeam.repairSale}}</span>
                    <span>{{ totalTeam.percent.repairSale }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Переданы без ремонта
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{totalTeam.drawingTransferred}}</span>
                    <span>{{ totalTeam.percent.drawingTransferred }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Новые / Думают
                </div>
                <div class="graph-item__value">
                    <div style="width: 86px" class="text-left"><b>{{ totalTeam.new }}</b> / {{ totalTeam.newDays }} дн.</div>
                    <div style="width: 86px;" class="text-right ml16"><b>{{ totalTeam.think }}</b> / {{ totalTeam.thinkDays }} дн.</div>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Мастера нужны позже
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.mastersLate }}</span>
                    <span>{{ totalTeam.percent.mastersLate }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Назначен
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.mastersSet }}</span>
                    <span>{{ totalTeam.percent.mastersSet }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Мастера не нужны
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.noMasters }}</span>
                    <span>{{ totalTeam.percent.noMasters }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Виталий
                </div>
                <div class="graph-item__value" v-if="totalTeam.masters[3965]">
                    <span class="text-bold">{{ totalTeam.masters[3965].count }}</span>
                    <span>{{ totalTeam.masters[3965].percent }}%</span>
                </div>
                <div class="graph-item__value" v-else>
                    <span class="text-bold">0</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Сергей
                </div>
                <div class="graph-item__value" v-if="totalTeam.masters[411]">
                    <span class="text-bold">{{ totalTeam.masters[411].count }}</span>
                    <span>{{ totalTeam.masters[411].percent }}%</span>
                </div>
                <div class="graph-item__value" v-else>
                    <span class="text-bold">0</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Ярослав
                </div>
                <div class="graph-item__value" v-if="totalTeam.masters[3966]">
                    <span class="text-bold">{{ totalTeam.masters[3966].count }}</span>
                    <span>{{ totalTeam.masters[3966].percent }}%</span>
                </div>
                <div class="graph-item__value" v-else>
                    <span class="text-bold">0</span>
                </div>
            </div>

            <div class="graph-item">
                <div class="graph-item__name">
                    Евгений
                </div>
                <div class="graph-item__value" v-if="totalTeam.masters[4974]">
                    <span class="text-bold">{{ totalTeam.masters[4974].count }}</span>
                    <span>{{ totalTeam.masters[4974].percent }}%</span>
                </div>
                <div class="graph-item__value" v-else>
                    <span class="text-bold">0</span>
                </div>
            </div>

            <!-- Мастера нужны позже к мастера не нужны-->
            <div class="graph-percent">
                {{ totalTeam.percent.mastersLateToNoMasters }}%
            </div>

            <!-- Мастера нужны позже к назначен -->
            <div class="graph-percent">
                {{ totalTeam.percent.mastersLateToMasterSet }}%
            </div>

        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Tumbler from '@/components/Tumbler.vue';
export default {
    name: "Sale",
    components: {
        Tooltip,
        Tumbler,
    },
    data() {
        return {
            currentYearIndex: -1,
            currentYearDate: 2023,
            form: {
                optionRepairSale: {
                    value: {id: 'all', name: 'Все'},
                    error: false,
                    repeatError: false,
                },
            },
        }
    },
    mounted(){
        this.currentYearIndex = this.yearsSale.indexOf(this.currentYearDate);
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            objects: state => state.statistic.objects,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
            total: state => state.statistic.sale,
            yearsSale: state => state.statistic.yearsSale,
        }),
        optionsTeam() {
            let items = [];
            for(let t in this.optionsProject.teams){
                items.push({
                    id: this.optionsProject.teams[t].xml_id,
                    name: this.optionsProject.teams[t].name,
                });
            }
            return items;
        },
        currentYear() {
            return this.yearsSale[this.currentYearIndex];
        },
        totalTeam(){
            return this.currentYear && this.total && this.total[this.currentYear][this.form.optionRepairSale.value.id] ? this.total[this.currentYear][this.form.optionRepairSale.value.id] : {}
        },
    },
}
</script>

<style scoped>
    .opacity-0 {
        opacity: 0 !important;
        cursor: default !important;
    }
</style>
<template>
    <div>
        <div class="popup__header">
            <div class="title">История отказов в ремонте</div>
            <div class="text-color-white-trans text-bold mt4">{{item.name}}</div>
        </div>


        <div class="popup__content">
			<div class="" v-for="(request, index) in item.refuseHistory">
				<div class="df df-space-between">
					<a target="_blank" :href="'/?search='+request.name" class="text-bold text-size-18">{{ request.name }}</a>
					<div class="text-size-xs text-color-grey">
						<span class="mr16">
							{{ request.props.flatArea }} м<sup>2</sup>
						</span>
						<span class="mr16">
							{{ request.props.priceRepair }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
						</span>
						<span class="">
							{{ request.props.priceRepairPerMeter }} руб/м<sup>2</sup>
						</span>
					</div>
				</div>
				<div class="text-bold mt4" >
					<span v-if="request.extProps.stamp === 'master_refused'">Мастер отказался:</span>
					<span v-else>Заказчик отказался:</span>
				</div>
				<div class="mt4">
                    <span v-html="convertNewLine(item.props.repairComment )"></span>
				</div>
                <hr v-if="item.refuseHistory.length > 1 && index+1 < item.refuseHistory.length" style="margin-top: 16px; margin-bottom: 16px;">
			</div>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import { convertNewLine } from "@/helpers/util/helper";

    export default {
        name: "RefuseHistory",
        components: {
            SubmitBtn,
            TextareaField,
        },
        methods: {
            convertNewLine(text) {
                return convertNewLine(text);
            },
        },
        data: function () {
            return {
                form: {
                    btnType: 'normal',
                },

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                options: state => state.options,
                item: state => state.popups.curItemData,
            }),
        },
    }
</script>

<style scoped>

</style>

<template>
    <vue-echarts :option="option" style="height: 224px" ref="chart" @zr:click="handleZrClick"
                 @globalout="clearDataIndex()"/>
    <div class="months-label">
        <div class="month" v-for="item in months">
            {{item}}
        </div>
    </div>
</template>

<style>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
.echarts {
    width: 990px;
    height: 286px;
}
.vue-echarts {
	height: 286px !important;
}
</style>

<script>
import { VueEcharts } from 'vue3-echarts';
import { number_format } from '@/helpers/formatted.js';

export default {
    name: 'BarChart',
    props: {
        months: {
            default() {
                return []
            }
        },
        incomes: {
            default() {
                return []
            }
        },
        forecast: {
            default() {
                return []
            }
        },
        maxProfit: {
            default: 100000
        },
        width: {
            default: 990
        },
        left: {
            default: 0
        },
        height: {
            default: 225
        },
        isRepair: {
            default: false
        }
    },
    components: {
        VueEcharts,
    },
    mounted() {
        //console.log(this.option);
    },
    methods: {
        clearDataIndex() {
            this.dataIndex = '';
        },
        number_format: function (v) {
            return number_format(v, 0, '', ' ')
        },
        handleZrClick(params) {
            let bar = this.$refs.chart;
            let dataIndex = Math.floor(params.offsetX / 27.5);

            if (this.dataIndex === dataIndex) {
                bar.dispatchAction({
                    type: 'hideTip',
                });
                this.dataIndex = '';

            } else {
                this.dataIndex = dataIndex;
            }
        },
        getIncomeTooltip(item) {
            let text = `<div class="tooltip top-center-direction" style="width: 220px;">
                <div class="tooltip__content">
                <div class="tooltip-ttl">${item.month} ${item.year}</div>`;
            if(item.appeals > 0){
                text += `<div class="df df-space-between">
                                    <div class="l">Обращения</div>
                                    <div class="r">${item.appeals}</div>
                                </div>`;   
            }
            if(item.requests){
                text += `<div class="df df-space-between">
                                    <div class="l">Заявки</div>
                                    <div class="r">${item.requests}</div>
                                </div>`;
            }
            if(item.measuresPredict){
                text += `<div class="df df-space-between">
                                        <div class="l">Прогноз замеров</div>
                                        <div class="r">${parseInt(item.measuresPredict)+parseInt(item.measures)}</div>
                                    </div>`;
            }
            else{
                text += `<div class="df df-space-between">
                                        <div class="l">Замеры</div>
                                        <div class="r">${item.measures}</div>
                                    </div>`;
            }
            text += `<div class="df df-space-between">
                                    <div class="l">Обычные</div>
                                    <div class="r">${item.requestNormal ? item.requestNormal : 0}</div>
                                </div>`;
            text += `<div class="df df-space-between">
                                    <div class="l">Дистанционные</div>
                                    <div class="r">${item.requestRemote ? item.requestRemote : 0}</div>
                                </div>`;
            if(!item.profitPredict && +item.measures > 0){
                text += `<div class="df df-space-between">
                                    <div class="l">Общая площадь</div>
                                    <div class="r">${item.area} м<sup>2</sup></div>
                                </div>`;
            }
            if(+item.profitPredict > 0 && +item.requestsPredict > 0){
                text += `<div class="df df-space-between">
                                    <div class="l">Прогноз площади</div>
                                    <div class="r">${item.area} м<sup>2</sup></div>
                                </div>`;
            }
            if(!item.profitPredict){
                text += `<div class="df df-space-between">
                            <div class="l">Доход</div>
                            <div class="r">${item.profitFormat ? item.profitFormat : 0} Р</div>
                        </div>`;
            }
            if(+item.profitPredict > 0){
                text += `<div class="df df-space-between">
                            <div class="l">Прогноз дохода</div>
                            <div class="r">${item.profitPredictFormat} Р</div>
                        </div>`

            }
            if(+item.profitPredict > 0 && +item.profit > 0){
                text += `<div class="df df-space-between" style="width: 196px;">
                    <div class="text-color-grey text-center yet">Уже сделано замеров<div class="text-bold text-size-s">${item.measures}</div></div>
                    <div class="text-color-grey text-center yet">Уже получено дохода<div class="text-bold text-size-s">${item.profitFormat} Р</div></div>
                </div>`;
            }
            text += `</div>
                    </div>`;
            return text;
        },
        getRepairTooltip(item) {
            return `
            <div class="tooltip top-center-direction" style="width: 220px;">
                <div class="tooltip__content">
                <div class="tooltip-ttl">${item.month} ${item.year}</div>
                <div class="df df-space-between">
                    <div class="l">Москва</div>
                    <div class="r">${item.countMoscow}</div>
                </div>
                <div class="df df-space-between">
                    <div class="l">Питер</div>
                    <div class="r">${item.countPiter}</div>
                </div>
                <div class="df df-space-between" style="width: 196px;">
                    <div class="text-color-grey text-center yet">Сумма за Москву<div class="text-bold text-size-s">${item.profitMoscow} Р</div></div>
                    <div class="text-color-grey text-center yet">Сумма за Питер<div class="text-bold text-size-s">${item.profitPiter} Р</div></div>
                </div>
                </div>
            </div>`;
        }

    },
    data() {
        return {
            dataIndex: '',

        }
    },
    watch: {
        incomes: {
            handler: function (val, oldVal) {
                this.$refs.chart.refreshOption();
            },
            deep: true
        },
        forecast: {
            handler: function (val, oldVal) {
                this.$refs.chart.refreshOption();
            },
            deep: true
        },
    },
    computed: {
        option() {
            return {
                grid: {
                    width: this.width + 'px',
                    left: this.left + 'px',
                    height: this.height + 'px',
                    containLabeL: true
                },
                title: {
                    show: false,
                },
                legend: {
                    show: false,
                },
                xAxis: {
                    type: 'category',
                    data: this.months,
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: ['#eee']
                        },
                    },
                    boundaryGap: true,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                      //color: '#545454',
                        //fontSize: 9,
                        //fontFamily: 'prox',
                        //margin: 8,
                        //align: 'center',
                        //width: 0,
                        //fontWeight: 'bold',
                    },
                },
                yAxis: {
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: ['#eee']
                        },
                    },
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    min: 0,
                    max: +this.maxProfit,
                },
                tooltip:  {
                    triggerOn: 'click',
                    trigger: 'axis',
                    formatter: (params)  => {
                        //console.log(params)
                        let item = params[0].data;
                        return this.isRepair ? this.getRepairTooltip(item) : this.getIncomeTooltip(item);
                    },
                    axisPointer: {
                        type: 'shadow',
                    },
                    enterable: true,
                    backgroundColor: 'transparent',
                    className: 'graph-tooltip',
                    position: function (point, params, dom, rect, size) {
                        // fixed at top
                        let x = 82.5 * params[0].dataIndex + 27;
                        return [x, '26%'];
                    }
                },
                series: [
                    {
                        stack: 'one',
                        name: 'bar1',
                        data: this.incomes,
                        type: 'bar',
                        showBackground: false,
                        itemStyle: {
                            color: '#FFC700'
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#E1B000',
                                backgroundColor: '#000',
                            },
                        },
                        barWidth: 79,
                        barGap: '-100%',
                        z: 2,
                        label: {
                            show: true,
                            position: [0, -9],
                            distance: 4,
                      		fontSize: 8,
                            fontWeight: 'bold',
                            color: '#545454',
                            formatter: (params) => {
								let txt;
								if(this.isRepair){
									txt = this.number_format(params.value) + ' Р' + ' / ' + params.data.requests + ' шт.';
								}else{
									txt = this.number_format(params.value) + ' Р';
								}
                                return txt;
                            },
                        },
                    },
                    {
                        stack: 'two',
                        name: 'bar2',
                        data: this.forecast,
                        type: 'bar',
                        showBackground: false,
                        itemStyle: {
                            color: 'rgba(255, 199, 0, 0.5)'
                        },
                        emphasis: {
                            itemStyle: {
                                color: 'rgba(255, 199, 0, 0.6)'
                            },
                        },
                        barWidth: 79,
                        z: 1,
                        label: {
                            show: true,
                            position: [0,-9],
                            fontSize: 8,
                            color: '#545454',
                            formatter: (params) => {
                                return this.number_format(params.value) + ' Р'
                            },
                        },
                    }
                ],
            }
        }
    }
}
</script>

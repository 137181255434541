<template>
    <div class="statistic-block">
        <div class="df df-space-between">
            <div class="text-size-xxl text-bold df">
                <span class="mr8">Расположение объектов</span>
                <tooltip
                    :direction="'right-center-direction'"
                    :is-btn-close="false"
                    :add-style="{width: 268 + 'px'}">
                    <template v-slot:tooltipBtn>
                        <img src="@/assets/img/info-blue.svg" alt="" class="info-icon">
                    </template>

                    <template v-slot:tooltipContent>
                        Этот блок показывает расположение объектов со старой и новой Базы с момента внедрения дистанционных замеров, то есть с 15 апреля 2020
                    </template>
                </tooltip>
            </div>
            <div class="text-size-xxl text-bold">
                {{ objects.all }}
            </div>
        </div>
        <div class="graph graph-location">
            <div class="graph-item">
                <div class="graph-item__name">
                    Москва и МО
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ objects.moscow }}</span>
                    <span>{{ objects.percent.moscow }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Санкт-Петербург и ЛО
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ objects.SPB }}</span>
                    <span>{{ objects.percent.SPB }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Остальные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ objects.other }}</span>
                    <span>{{ objects.percent.other }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';

export default {
    name: "Objects",
    components: {
        Tooltip,
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            objects: state => state.statistic.objects,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
        }),
    },
}
</script>

<style scoped>

</style>
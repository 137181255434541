<template>
    <div class="statistic-block">
        <div class="df df-space-between">
            <div class="text-size-xxl text-bold df">
                <span class="mr8">Заявки с предоплатой</span>
                <tooltip
                    :direction="'right-center-direction'"
                    :is-btn-close="false"
                    :add-style="{width: 276 + 'px'}">
                    <template v-slot:tooltipBtn>
                        <img src="@/assets/img/info-blue.svg" alt="" class="info-icon">
                    </template>

                    <template v-slot:tooltipContent>
                        Этот блок показывает платные заявки<br>
                        за весь период до перехода<br>
                        на бесплатные заявки 24.08.2021
                    </template>
                </tooltip>
            </div>
            <div class="text-size-xxl text-bold">
                {{ prepay.all }}
            </div>
        </div>
        <div class="graph graph-requests">
            <div class="graph-item">
                <div class="graph-item__name">
                    Мобайл
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ prepay.mobile }}</span>
                    <span>{{ prepay.percent.mobile }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Новые
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">0</span>
                    <span>0 дн.</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Десктоп
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ prepay.desktop }}</span>
                    <span>{{ prepay.percent.desktop }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Обычные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ prepay.normal }}</span>
                    <span>{{ prepay.percent.normal }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Дистанционные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ prepay.remote }}</span>
                    <span>{{ prepay.percent.remote }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Одобрены
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ prepay.verify }}</span>
                    <span>{{ prepay.percent.verify }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Отклонены
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ prepay.refuse }}</span>
                    <span>{{ prepay.percent.refuse }}%</span>
                </div>
            </div>

            <!-- Обычные к одобреным -->
            <div class="graph-percent">
                {{ prepay.percent.normalToVerify }}%
            </div>

            <!-- Обычные к отклонённым -->
            <div class="graph-percent">
                {{ prepay.percent.normalToRefuse }}%
            </div>

            <!-- Дистанционные к одобреным -->
            <div class="graph-percent">
                {{ prepay.percent.remoteToVerify }}%
            </div>

            <!-- Дистанционные к отклонённым -->
            <div class="graph-percent">
                {{ prepay.percent.remoteToRefuse }}%
            </div>
        </div>
        <div class="reasons-requests" >
            <div class="text-size-18 text-bold">
                Причины отклонения <span style="display: none;">{{ getAllCount(prepay.reasonReject) }}</span>
            </div>
            <div class="df df-space-between mt8" v-for="reasonReject in prepay.reasonReject">
                {{ reasonReject.reason }}
                <div class="data df">
                    <div class="text-right text-color-grey text-size-xs mr8">{{ reasonReject.percent }}%</div>
                    <div class="text-right" style="width: 33px;">{{ reasonReject.count }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';

export default {
    name: "Prepay",
    components: {
        Tooltip,
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            prepay: state => state.statistic.prepay,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
        }),
    },
    methods: {
        getAllCount(arr) {
            let count = 0;
            for(let i in arr) {
                count += parseInt(arr[i].count);
            }

            return count;
        },
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="statistic-block">
        <div class="df df-space-between" style="margin-bottom: -11px;">
            <div class="text-size-xxl text-bold df">
                Доход
                <div class="year-select ml24">
                    <div class="arrow left" @click="currentYearIndex - 1 < 0 ? currentYearIndex = 0  : currentYearIndex = currentYearIndex - 1"></div>
                    <div class="text-size-xxl text-bold text-center year">
                        {{ years[currentYearIndex]}}
                    </div>
                    <div class="arrow right" @click="currentYearIndex + 1 < years.length ? currentYearIndex += 1 : currentYearIndex"></div>
                </div>
            </div>
            <div class="text-size-s text-bold text-color-grey" style="text-align:center">
                Средняя площадь объекта (min 50 м<sup>2</sup>): {{sumSquare50max}} м<sup>2</sup><br>
                Фактическая средняя площадь: {{sumSquare}} м<sup>2</sup>
            </div>
            <div class="text-size-xxl text-bold">
                {{number_format(sumProfit)}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                <div v-if="true" class="text-color-grey text-size-s forecast">
                    <div>Прогноз дохода за год: {{number_format(sumProfitPredict)}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></div>
                    <div class="mt4">Предоплаченные замеры: {{number_format(sumProfitPrePayed)}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></div>
                </div>
            </div>
        </div>

        <bar-chart :months="months" :incomes="incomes" :forecast="forecast" :maxProfit="maxProfit"></bar-chart>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import BarChart from '@/components/BarChart.vue';
import {number_format} from '@/helpers/formatted.js';

export default {
    name: "Graph",
    components: {
        Tooltip,
        BarChart,
    },
    data() {
        let date = new Date();
        return {
            currentYearIndex: -1 ,
            currentDateYear: date.getFullYear(),
            currentDateMonth: date.getMonth() + 1,
            months: ["ЯНВАРЬ","ФЕВРАЛЬ","МАРТ","АПРЕЛЬ","МАЙ","ИЮНЬ","ИЮЛЬ","АВГУСТ","СЕНТЯБРЬ","ОКТЯБРЬ","НОЯБРЬ","ДЕКАБРЬ"],
        }
    },
    mounted() {
        this.currentYearIndex = this.years.indexOf(this.currentDateYear);
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            graph: state => state.statistic.graph,
            years: state => state.statistic.years,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
            showSumProfitPredict: state => state.statistic.showSumProfitPredict,
        }),
        sumSquare50max(){
            let result = 0, area = 0, count = 0;
            if(this.currentYear != {}) {
                let year = this.currentYear;
                for(let m in year){
                    area += parseFloat(+year[m].area);
                    count+= +year[m].measures + +year[m].measuresPredict;
                }
            }
            result = area/count;
            return +result.toFixed(1);
        },
        sumSquare(){
            let result = 0, areaReal = 0, count = 0;
            if(this.currentYear != {}) {
                let year = this.currentYear;
                for(let m in year){
                    areaReal += parseFloat(+year[m].areaReal);
                    count+= +year[m].measures + +year[m].measuresPredict;
                }
            }
            result = areaReal/count;
            return +result.toFixed(1);
        },
        sumProfit(){
            let result = 0;
            if(this.currentYear != {}) {
                let year = this.currentYear;
                for(let m in year){
                    result += +year[m].profit;
                }
            }
            return +result;
        },
        currentYear(){
            if(this.graph && this.years && this.currentYearIndex  != -1) {
                return this.graph[this.years[this.currentYearIndex]] ? this.graph[this.years[this.currentYearIndex]] : {};
            } else {
                return {}
            }
        },
        sumProfitPredict(){
            let result = 0;
            if(this.currentYear != {}) {
                let year = this.currentYear;
                if (+this.currentDateYear > +this.years[this.currentYearIndex]) {
                    for(let m in year){
                        result += +year[m].profit;
                    }
                    return result;
                }
                if (+this.currentDateYear < +this.years[this.currentYearIndex]) {
                    year = this.graph[this.years[this.years.indexOf(this.currentDateYear)]];
                    for(let m in year){
                        if(+year[m].monthNum < +this.currentDateMonth){
                            result += +year[m].profit;
                        }
                    }
                    if (this.currentDateMonth == 1) {
                        return +result * 12;
                    }
                    return +result / (this.currentDateMonth - 1) * 12;
                }
                if (+this.currentDateYear == +this.years[this.currentYearIndex]) {
                    if (this.currentDateMonth == 1) {
                        year = this.graph[this.years[this.currentYearIndex - 1]];
                        for(let m in year){
                            result += +year[m].profit;
                        }
                        return result;
                    } else {
                        for(let m in year){
                            if(+year[m].monthNum < +this.currentDateMonth){
                                result += +year[m].profit;
                            }
                        }
                    }
                }
                if (this.currentDateMonth == 1) {
                    return +result * 12;
                }
                return +result / (this.currentDateMonth - 1) * 12;
            }
            return +result;

        },
        sumProfitPrePayed(){
            let result = 0;
            if(this.currentYear != {}) {
                let year = this.currentYear;
                if (this.currentDateYear > this.years[this.currentYearIndex]) {
                    for(let m in year){
                        result += +year[m].profit;
                    }
                    return result;
                }
                if (this.currentDateYear == this.years[this.currentYearIndex]) {
                    for(let m in year){
                        if(year[m].monthNum == this.currentDateMonth){
                            result += +year[m].profitPredict;
                        }
                        else if(year[m].monthNum > this.currentDateMonth){
                            result += +year[m].profitPredict;
                        }
                    }
                    return result;
                }

                if (this.currentDateYear < this.years[this.currentYearIndex]) {
                    for(let m in year){
                        result += +year[m].profitPredict;
                    }
                }

            }
            return +result;
        },
        maxProfit(){
            let result = 1000000;
            if(this.currentYear != {}) {
                let year = this.currentYear;
                for(let m in year){
                    if(result < +year[m].profit){
                        result = +year[m].profit;
                    }
                    if(result < +year[m].profitPredict){
                        result = +year[m].profitPredict;
                    }
                }
            }
            return (result * (1 + 0.1));
        },
        incomes(){
            let result = [];
            if(this.currentYear != {}) {
                let year = this.currentYear;
                let index = 0;
                for(let m in this.months){
                    if(year[index] && year[index].month.toUpperCase() == this.months[m]){
                        result.push({
                            "value":year[index].profit && !year[index].profitPredict ? year[index].profit : null,
                            "measures":year[index].measures,
                            "measuresPredict":year[index].measuresPredict,
                            "area":year[index].area,
                            "profit":year[index].profit,
                            "profitPredict":year[index].profitPredict,
                            "profitFormat":year[index].profitFormat,
                            "profitPredictFormat":year[index].profitPredictFormat,
                            'month': this.months[m],
                            'year': this.years[this.currentYearIndex],
                            'requests': year[index].requests,
                            'requestRemote': year[index].requestRemote,
                            'requestNormal': year[index].requestNormal,
                            'appeals': year[index].appeals,
                        });
                        index++;
                    } else {
                        result.push({
                            "value":null,
                            "measures":0,
                            "area":0,
                            "profit":0,
                            "profitPredict":0,
                            'month': this.months[m],
                            'year': this.years[this.currentYearIndex]
                        });
                    }
                }
            }

            return result;
        },
        forecast(){
            let result = [];
            if(this.currentYear != {}) {
                let year = this.currentYear;
                let index = 0;
                for(let m in this.months){
                    if(year[index] && year[index].month.toUpperCase() == this.months[m]){
                        result.push({
                            "value":year[index].profitPredict ? year[index].profitPredict : null,
                            "measures":year[index].measures,
                            "measuresPredict":year[index].measuresPredict,
                            "area":year[index].area,
                            "profit":year[index].profit,
                            "profitPredict":year[index].profitPredict,
                            "profitFormat":year[index].profitFormat,
                            "profitPredictFormat":year[index].profitPredictFormat,
                            'month': this.months[index],
                            'year': this.years[this.currentYearIndex]
                        });
                        index++;
                    } else {
                        result.push({
                            "value":null,
                            "measures":0,
                            "area":0,
                            "profit":0,
                            "profitPredict":0,
                            'month': this.months[m],
                            'year': this.years[this.currentYearIndex]
                        });
                    }
                }
            }
            return result;
        }
    },
    methods: {
        number_format: function (v) {
            return number_format(v, 0, '', ' ')
        },
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="statistic-block">
        <div class="df df-space-between">
            <div class="text-size-xxl text-bold df">
                <span class="mr8">Автопродажи ремонтов общие</span>
            </div>
            <div class="text-size-xxl text-bold">
                {{ autosale.total }}
            </div>
        </div>
        <div class="graph graph-autosale-all">
            <div class="graph-item">
                <div class="graph-item__name">
                    Предлагаем ремонт
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ autosale.need }}</span>
                    <span>{{ autosale.percent.need }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Не предлагаем ремонт
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ autosale.notNeed }}</span>
                    <span>{{ autosale.percent.notNeed }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Регионы
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ autosale.region }}</span>
                    <span>{{ autosale.percent.region }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "AutosaleAll",
    components: {
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
            autosale: state => state.statistic.autosale,
        }),
    },
}
</script>

<style scoped>

</style>
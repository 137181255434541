<template>
    <div class="statistic-block">
        <div class="df df-space-between">
            <div class="text-size-xxl text-bold df">
                <span class="mr8">Обращения</span>
                <tooltip
                        :direction="'right-center-direction'"
                        :is-btn-close="false"
                        :add-style="{width: 268 + 'px'}">
                    <template v-slot:tooltipBtn>
                        <img src="@/assets/img/info-blue.svg" alt="" class="info-icon">
                    </template>

                    <template v-slot:tooltipContent>
                        В этой статистике важен только процент отклонённых обращений и причины их отклонения. Процентовка оплаченных заявок и вытекающие блоки являются примерными, потому что мы не отслеживаем заявки, созданные по обращению, но на другой номер телефона
                    </template>
                </tooltip>
            </div>
            <div class="text-bold">
                Думают: {{appeals.think}} ({{appeals.thinkDays}} дн.)
            </div>
            <div class="text-size-xxl text-bold">
                {{appeals.all}}
            </div>
        </div>
        <div class="graph graph-appeals">
            <div class="graph-item">
                <div class="graph-item__name">
                    Создана заявка
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{appeals.created}}</span>
                    <span>{{appeals.percent.created}}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Отправлена ссылка
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{appeals.link}}</span>
                    <span>{{appeals.percent.link}}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Отклонены
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{appeals.rejected}}</span>
                    <span>{{appeals.percent.rejected}}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Оплаченные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{appeals.paid}}</span>
                    <span>{{appeals.percent.paid}}%</span>
                </div>
                <div class="text-size-xs text-center mt4">
                    {{appeals.percent.paidAll}}% от {{appeals.all}}
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Неоплаченные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{appeals.notPaid}}</span>
                    <span>{{appeals.percent.notPaid}}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Обычные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{appeals.normal}}</span>
                    <span>{{appeals.percent.normal}}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Дистанционные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{appeals.remote}}</span>
                    <span>{{appeals.percent.remote}}%</span>
                </div>
            </div>
        </div>
        <div class="reasons-appeals">
            <div class="text-size-18 text-bold">
                Причины отклонения <span style="display: none;">666</span>
            </div>
            <div class="df df-space-between mt8" v-for="reason in appeals.reasons">
                {{reason.name}}
                <div class="data df">
                    <div class="text-right text-color-grey text-size-xs mr8">{{reason.percent}}%</div>
                    <div class="text-right" style="width: 33px;">{{reason.count}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Tumbler from '@/components/Tumbler.vue';

export default {
    name: "Appeals",
    components: {
        Tooltip,
        Tumbler,
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            appeals: state => state.statistic.appeals,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
        }),
    },
}
</script>

<style scoped>

</style>

<template>
    <div class="content content--no-sub" v-if="graph && years.length > 0 || user.groupCode == 'F' && masters">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">Статистика</div>
            </div>

            <div class="main-title__right">
				<div class="df mr32">
					<span class="i i-info mr4"></span>
					<span class="t">Статистика обновляется 1 раз в час</span>
				</div>
                <a v-if="graph && years && user.groupCode != 'F'" @click="update" href="javascript:void(0);" class="link-icon proj-statistic-update">
                    <span class="i i-refresh" :class="{start: startUpdate}"></span>
                    <span class="text">Обновить</span>
                </a>
            </div>
        </div>

        <section class="container statistic-container" v-if="isUserLoaded">
            <graph v-if="user.groupCode != 'F' && years.length > 0"></graph>
            <free-requests v-if="user.groupCode != 'F' && years.length > 0"/>
            <objects v-if="user.groupCode != 'F'"></objects>
            <autosale-all v-if="user.groupCode != 'F'"></autosale-all>
            <autosale-moscow v-if="user.groupCode != 'F'"></autosale-moscow>
            <autosale-peter v-if="user.groupCode != 'F'"></autosale-peter>
            <sale v-if="user.groupCode != 'F'"></sale>
            <repair></repair>
            <repair-graph v-if="yearsRepair.length > 0"></repair-graph>
            <prepay v-if="user.groupCode != 'F'"></prepay>
            <appeals v-if="user.groupCode != 'F' && years.length > 0"/>
            <div  v-if="user.groupCode != 'F'" class="statistic-block">
                <div class="graph-item outside-block">
                    <div class="graph-item__name"> Количество переходов на zemscontrol.ru </div>
                    <div class="graph-item__value"><span class="text-bold">{{other.zemscontrol}}</span></div>
                </div>
            </div>

        </section>

        <popup :show="popups.refuseHistory.show"
               :width="540"
               :name="popups.refuseHistory.name">
            <refuse-history />
        </popup>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import Tooltip from '@/components/Tooltip.vue';
    import Popup from '@/components/modal/Popup.vue';
    import BarChart from '@/components/BarChart.vue';
	import BarChartRequests from '@/components/BarChartRequests.vue';
    import {number_format} from '@/helpers/formatted.js';
    import RefuseHistory from '@/components/modal/requests/RefuseHistory.vue';
	import Tumbler from '@/components/Tumbler.vue';
	import FreeRequests from '@/components/Statistic/FreeRequests.vue';
	import Appeals from '@/components/Statistic/Appeals.vue';
	import Objects from '@/components/Statistic/Objects.vue';
	import Sale from '@/components/Statistic/Sale.vue';
	import Repair from '@/components/Statistic/Repair.vue';
	import RepairGraph from '@/components/Statistic/RepairGraph.vue';
	import Graph from '@/components/Statistic/Graph.vue';
	import Prepay from '@/components/Statistic/Prepay.vue';
    import AutosaleAll from '@/components/Statistic/AutosaleAll.vue';
    import AutosaleMoscow from '@/components/Statistic/AutosaleMoscow.vue';
    import AutosalePeter from '@/components/Statistic/AutosalePeter.vue';

    export default {
        name: "Statistic",
        props: {

        },
        components: {
            Tooltip,
            Popup,
            BarChart,
			BarChartRequests,
            RefuseHistory,
			Tumbler,
            FreeRequests,
            Appeals,
            Objects,
            Sale,
            Repair,
            RepairGraph,
            Graph,
            Prepay,
            AutosaleAll,
            AutosaleMoscow,
            AutosalePeter,
        },
        data: function(){
            let date = new Date();
            return {
                interval: false,
                currentYearIndex: -1 ,
                currentYearRepairIndex: -1 ,
                currentYearDate: date.getFullYear(),
                months: ["ЯНВАРЬ","ФЕВРАЛЬ","МАРТ","АПРЕЛЬ","МАЙ","ИЮНЬ","ИЮЛЬ","АВГУСТ","СЕНТЯБРЬ","ОКТЯБРЬ","НОЯБРЬ","ДЕКАБРЬ"],
                refuseHistoryBtn: false,
				form: {
                    option: {
                        value: {id: 0, name: 'Все'},
                        error: false,
                        repeatError: false,
                    },
                    optionFreeRequests: {
                        value: {id: 0, name: 'Все'},
                        error: false,
                        repeatError: false,
                    },
                    optionRepairSale: {
                        value: {id: 0, name: 'Все'},
                        error: false,
                        repeatError: false,
                    },
                },
                isDevelop: process.env.NODE_ENV === 'development'
            }
        },
        mounted(){
            this.$store.dispatch('statistic/getData').then(response => {
                let date = new Date();
                if(this.years.length > 0){
                    this.currentYearIndex = this.years.indexOf(date.getFullYear().toString());
                    this.currentYearRepairIndex = this.yearsRepair.indexOf(date.getFullYear().toString());
                    this.$store.dispatch('statistic/checkUpdate').then((e)=>{
                        if(e){
                            this.$store.commit('statistic/setStartUpdate',true);
                            this.interval =  setInterval(()=>{
                                this.$store.dispatch('statistic/checkUpdate').then((e)=>{
                                    if(!e){
                                        clearInterval(this.interval);
                                        this.$store.dispatch('statistic/getItems');
                                    }
                                });
                            },5000);
                        }
                    });
                }
            });
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                graph: state => state.statistic.graph,
                years: state => state.statistic.years,
                graphRepair: state => state.statistic.repairGraph,
                yearsRepair: state => state.statistic.yearsRepair,
                total: state => state.statistic.total,
                other: state => state.statistic.other,
                masters: state => state.statistic.masters,
                startUpdate: state => state.statistic.startUpdate,
                isUserLoaded: state => state.auth.isLoaded,
                user: state => state.auth.user,
                optionsProject: state => state.options.items,
            }),
            currentYear(){
                if(this.graph && this.years && this.currentYearIndex  != -1) {
                    return this.graph[this.years[this.currentYearIndex]] ?this.graph[this.years[this.currentYearIndex]] : {};
                } else {
                    return {}
                }
            },
            currentYearRepair(){
                if(this.graphRepair && this.yearsRepair && this.currentYearRepairIndex  != -1) {
                    return this.graphRepair[this.yearsRepair[this.currentYearRepairIndex]] ?this.graphRepair[this.yearsRepair[this.currentYearRepairIndex]] : {};
                } else {
                    return {}
                }
            },
            maxProfitRepair(){
                let result = 100000;
                if(this.currentYearRepair != {}) {
                    let year = this.currentYearRepair;
                    for(let m in year){
                        if(result < +year[m].profit){
                            result = +year[m].profit;
                        }
                    }
                }
                return +result;
            },
            sumArea(){
                let result = 0;
                return +result;
            },
            sumProfitRepair(){
                let result = 0;
                if(this.currentYearRepair != {}) {
                    let year = this.currentYearRepair;
                    for(let m in year){
                        result += +year[m].profit;
                    }
                }
                return +result;
            },
            countRepair(){
                let result = 0;
                if(this.currentYearRepair != {}) {
                    let year = this.currentYearRepair;
                    for(let m in year){
                        result += +year[m].measures;
                    }
                }
                return +result;
            },
            incomesRepair(){
                let result = [];
                if(this.currentYearRepair != {}) {
                    let year = this.currentYearRepair;
                    let index = 0;
                    for(let m in this.months){
                        if(year[index] && year[index].month.toUpperCase() == this.months[m]){
                            result.push({
                                "value":year[index].profit ? year[index].profit : null,
                                "measures":year[index].measures,
                                "area":year[index].area,
                                "profit":year[index].profit,
                                "profitFormat":year[index].profitFormat,
                                'month': this.months[m],
                                'year': this.years[this.currentYearIndex],
                            });
                            index++;
                        } else {
                            result.push({
                                "value":null,
                                "measures":0,
                                "area":0,
                                "profit":0,
                                'month': this.months[m],
                                'year': this.years[this.currentYearIndex]
                            });
                        }
                    }
                }

                return result;
            },

        },
        methods: {
            round(value){
                value = value.toFixed();
                let len = value.toString().length;
                if(len < 5){
                    return Math.pow(10, len);
                } else {
                    let m = Math.pow(10, len - 1 || 0);
                    return Math.ceil(value / m) * m;
                }

            },
            number_format: function (v) {
                return number_format(v, 0, '', ' ')
            },
            update() {
                if(!this.startUpdate){
                    this.$store.dispatch('statistic/postStartUpdate');
                    this.interval =  setInterval(()=>{
                        this.$store.dispatch('statistic/checkUpdate').then((e)=>{
                            if(!e){
                                clearInterval(this.interval);
                                this.$store.dispatch('statistic/getData');
                            }
                        });
                    },5000);
                }
            },
            openRefuseHistory(item){
                if(item.refuseHistory.length > 0){
                    this.$store.dispatch('popups/open', {name: this.popups.refuseHistory.name, request: item});
                } else {
                    this.refuseHistoryBtn = true;

                    this.$store.dispatch('popups/getData',{id: item.user, modalData: 'RefuseHistoryMaster'}).then(response => {
                        this.refuseHistoryBtn = false;
                        this.$store.commit('statistic/setMastersProps', {item: item, props: {
                                refuseHistory: response.items,
                            }});
                        this.$nextTick(()=>{
                            for(let m in this.masters.items){
                                if(this.masters.items[m].id == item.id){
                                    this.$store.dispatch('popups/open', {name: this.popups.refuseHistory.name, request: this.masters.items[m]});
                                }
                            }

                        })
                    });

                }

            }
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
<style scoped>
    .i-refresh.start {
        animation: 1s linear 0s normal none infinite running rot;
        -webkit-animation: 1s linear 0s normal none infinite running rot;
    }
    @keyframes rot {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes rot {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>

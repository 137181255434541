<template>
    <vue-echarts :option="option" style="height: 224px" ref="chart" @zr:click="handleZrClick"
                 @globalout="clearDataIndex()"/>
    <div class="months-label --requests">
        <div class="month" v-for="item in months">
            {{item}}
        </div>
    </div>
	<div class="legend">
		<div class="legend-item --purple">
			Обычные замеры
		</div>
		<div class="legend-item --orange">
			Дистанционные замеры
		</div>
		<div class="legend-item --red">
			Отклонённые заявки
		</div>
	</div>
</template>

<style>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
.echarts {
    width: 990px;
    height: 286px;
}
.vue-echarts {
	height: 286px !important;
}
</style>

<script>
import {VueEcharts} from 'vue3-echarts';
import {number_format} from '@/helpers/formatted.js';

export default {
    name: 'BarChartRequets',
    props: {
        months: {
            default() {
                return []
            }
        },
        incomes: {
            default() {
                return []
            }
        },
        forecast: {
            default() {
                return []
            }
        },
        maxProfit: {
            default: 100000
        },
        width: {
            default: 990
        },
        left: {
            default: 0
        },
        height: {
            default: 225
        }
    },
    components: {
        VueEcharts,
    },
    mounted() {
        //console.log(this.option);
    },
    methods: {
        clearDataIndex() {
            this.dataIndex = '';
        },
        number_format: function (v) {
            return number_format(v, 0, '', ' ')
        },
        handleZrClick(params) {
            let bar = this.$refs.chart;
            let dataIndex = Math.floor(params.offsetX / 27.5);

            if (this.dataIndex === dataIndex) {
                bar.dispatchAction({
                    type: 'hideTip',
                });
                this.dataIndex = '';

            } else {
                this.dataIndex = dataIndex;
            }
        },
		handleSm(){
			//console.log(300);
		}

    },
    data() {
        return {
            dataIndex: '',

        }
    },
    watch: {
        incomes: {
            handler: function (val, oldVal) {
                this.$refs.chart.refreshOption();
            },
            deep: true
        },
        forecast: {
            handler: function (val, oldVal) {
                this.$refs.chart.refreshOption();
            },
            deep: true
        },
    },
    computed: {
        option() {
            return {
                grid: {
                    width: this.width + 'px',
                    left: this.left + 'px',
                    height: this.height + 'px',
                    containLabeL: true
                },
                title: {
                    show: false,
                },
                legend: {
                    show: true,
					bottom: -44
                },
                xAxis: {
                    type: 'category',
                    data: this.months,
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: ['#eee']
                        },
                    },
                    boundaryGap: true,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                      //color: '#545454',
                        //fontSize: 9,
                        //fontFamily: 'prox',
                        //margin: 8,
                        //align: 'center',
                        //width: 0,
                        //fontWeight: 'bold',
                    },
                },
                yAxis: {
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ['#eee']
                        },
                    },
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    min: 0,
                    max: +this.maxProfit,
                },
                tooltip: {
                    triggerOn: 'click',
                    trigger: 'axis',
                    formatter: function (params) {
                        //console.log(params)
                        let item = params[0].data;
                        //console.log(item)
                        let text = `<div class="tooltip top-center-direction" style="width: 260px;">
                                          <div class="tooltip__content">
                                            <div class="tooltip-ttl" @click="handleSm">${item.month} ${item.year}</div>`;
                        if(item.countFirst){
                            text += `<div class="df df-space-between">
                                                <div class="l">Заявки</div>
                                                <div class="r">${item.countFirst}</div>
                                            </div>`;
                        }
                        text += `<div class="df df-space-between">
                                                <div class="l">Замеры</div>
                                                <div class="r">${item.requests}</div>
                                            </div>`;
                        if(+item.requests > 0){
                            text += `<div class="df df-space-between">
                                                <div class="l">Общая площадь</div>
                                                <div class="r">${item.area} м<sup>2</sup></div>
                                            </div>`;
                        }
                        if(+item.profit > 0){
                            text += `<div class="df df-space-between">
                                        <div class="l">Доход</div>
                                        <div class="r">${item.profitFormat} Р</div>
                                    </div>`;
                        }
                        if(+item.profitPredict > 0){
                            text += `<div class="df df-space-between">
                                        <div class="l">Прогноз дохода</div>
                                        <div class="r">${item.profitPredictFormat} Р</div>
                                    </div>`

                        }
                        text += `</div>
                              </div>`;
                        return text;
                    },
                    axisPointer: {
                        type: 'shadow',
                    },
                    enterable: true,
                    backgroundColor: 'transparent',
                    className: 'graph-tooltip',
                    position: function (point, params, dom, rect, size) {
                        // fixed at top
                        let x = 82.5 * params[0].dataIndex + 27;
                        return [x, '26%'];
                    }
                },
                series: [
                    {
                        name: 'bar1',
                        data: this.incomes,
                        type: 'bar',
						barGap: 0,
                        showBackground: false,
                        itemStyle: {
                            color: '#A981CB'
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#A981CB',
                                backgroundColor: '#000',
                            },
                        },
                        barWidth: 24,
                        z: 2,
                        label: {
                            show: true,
                            position: 'insideBottomLeft',
                            distance: 4,
                      		fontSize: 9,
                            fontWeight: 'bold',
                            color: '#fff',
                            formatter: (params) => {
                                return this.number_format(params.value)
                            },
                        },
                    },
					{
                        name: 'bar2',
                        data: this.incomes,
                        type: 'bar',
                        showBackground: false,
                        itemStyle: {
                            color: '#FF9900'
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#FF9900',
                                backgroundColor: '#000',
                            },
                        },
                        barWidth: 24,
                        z: 2,
                        label: {
							show: true,
                            position: 'insideBottomLeft',
                            distance: 4,
                      		fontSize: 9,
                            fontWeight: 'bold',
                            color: '#fff',
                            formatter: (params) => {
                                return this.number_format(params.value)
                            },
                        },
                    },
					{
                        name: 'bar3',
                        data: this.incomes,
                        type: 'bar',
                        showBackground: false,
                        itemStyle: {
                            color: '#DB5A5A'
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#DB5A5A',
                                backgroundColor: '#000',
                            },
                        },
                        barWidth: 24,
                        z: 2,
                        label: {
							show: true,
                            position: 'insideBottomLeft',
                            distance: 4,
                      		fontSize: 9,
                            fontWeight: 'bold',
                            color: '#fff',
                            formatter: (params) => {
                                return this.number_format(params.value)
                            },
                        },
                    },
                ],
            }
        }
    }
}
</script>

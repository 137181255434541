<template>
    <div class="statistic-block">
        <div class="df df-space-between" style="margin-bottom: -11px;">
            <div class="text-size-xxl text-bold df">
                Доход с ремонтов
                <div class="year-select ml24" :class="{'no-repairs': !(yearsRepair.length > 1)}">
                    <div class="arrow left" v-if="yearsRepair.length > 1" @click="currentYearRepairIndex - 1 < 0 ? currentYearRepairIndex = 0  : currentYearRepairIndex = currentYearRepairIndex - 1"></div>
                    <div class="text-size-xxl text-bold text-center year">
                        {{ yearsRepair[currentYearRepairIndex]}}
                    </div>
                    <div class="arrow right" v-if="yearsRepair.length > 1" @click="currentYearRepairIndex + 1 < yearsRepair.length ? currentYearRepairIndex += 1 : currentYearRepairIndex"></div>
                </div>
            </div>
            <div class="text-size-s text-bold text-color-grey" style="text-align:center">
                Всего объектов за год: {{ countRepair }} шт. <br>
                Средний доход с объекта: {{sumProfitRepair > 0 ? number_format(sumProfitRepair / countRepair) : 0}} ₽
            </div>
            <div class="text-size-xxl text-bold ">
                {{number_format(sumProfitRepair)}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                <div class="text-color-red text-size-s forecast" v-if="graphRepair.orderNotSubmittedSum > 0 && +yearsRepair[currentYearRepairIndex] >= +currentYearDate">
                    Задолженность мастеров: {{number_format(graphRepair.orderNotSubmittedSum)}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span> ({{graphRepair.orderNotSubmitted}} шт.)
                </div>
            </div>
        </div>

        <bar-chart :is-repair="true" :months="months" :incomes="incomesRepair" :maxProfit="maxProfitRepair"></bar-chart>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import BarChart from '@/components/BarChart.vue';
import {number_format} from '@/helpers/formatted.js';

export default {
    name: "RepairGraph",
    components: {
        Tooltip,
        BarChart,
    },
    data() {
        let date = new Date();
        return {
            currentYearDate: date.getFullYear(),
            currentYearRepairIndex: -1,
            months: ["ЯНВАРЬ","ФЕВРАЛЬ","МАРТ","АПРЕЛЬ","МАЙ","ИЮНЬ","ИЮЛЬ","АВГУСТ","СЕНТЯБРЬ","ОКТЯБРЬ","НОЯБРЬ","ДЕКАБРЬ"],
        }
    },
    mounted() {
        this.currentYearRepairIndex = this.yearsRepair.indexOf(this.currentYearDate);
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            graphRepair: state => state.statistic.repairGraph,
            yearsRepair: state => state.statistic.yearsRepair,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
        }),
        currentYearRepair(){
            if(this.graphRepair.items && this.yearsRepair && this.currentYearRepairIndex  != -1) {
                return this.graphRepair.items[this.yearsRepair[this.currentYearRepairIndex]] ? this.graphRepair.items[this.yearsRepair[this.currentYearRepairIndex]] : {};
            } else {
                return {}
            }
        },
        maxProfitRepair(){
            let result = 100000;
            if(this.currentYearRepair != {}) {
                let year = this.currentYearRepair;
                for(let m in year){
                    if(result < +year[m].profit){
                        result = +year[m].profit;
                    }
                }
            }
            return +result;
        },
        sumArea(){
            let result = 0;
            return +result;
        },
        sumProfitRepair(){
            let result = 0;
            if(this.currentYearRepair != {}) {
                let year = this.currentYearRepair;
                for(let m in year){
                    result += +year[m].profit;
                }
            }
            return +result;
        },
        countRepair(){
            let result = 0;
            if(this.currentYearRepair != {}) {
                let year = this.currentYearRepair;
                for(let m in year){
                    result += +year[m].requests;
                }
            }
            return +result;
        },
        incomesRepair(){
            let result = [];
            if(this.currentYearRepair != {}) {
                let year = this.currentYearRepair;
                let index = 0;
                for(let m in this.months){
                    if(year[index] && year[index].month.toUpperCase() == this.months[m]){
                        result.push({
                            "value":year[index].profit ? year[index].profit : null,
                            "requests":year[index].requests,
                            "area":year[index].area,
                            "profit":year[index].profit,
                            "profitFormat":year[index].profitFormat,
                            'month': this.months[m],
                            'year': this.yearsRepair[this.currentYearRepairIndex],
                            'countPiter': year[index].countPiter ? year[index].countPiter : 0,
                            'countMoscow': year[index].countMoscow ? year[index].countMoscow : 0,
                            'profitPiter': year[index].profitPiterFormat ? year[index].profitPiterFormat : 0,
                            'profitMoscow': year[index].profitMoscowFormat ? year[index].profitMoscowFormat : 0,
                        });
                        index++;
                    } else {
                        result.push({
                            "value":null,
                            "requests":0,
                            "area":0,
                            "profit":0,
                            'month': this.months[m],
                            'year': this.yearsRepair[this.currentYearRepairIndex],
                            'countPiter': 0,
                            'countMoscow': 0,
                            'profitPiter': 0,
                            'profitMoscow': 0,
                        });
                    }
                }
            }

            return result;
        },
    },
    methods: {
        number_format: function (v) {
            return number_format(v, 0, '', ' ')
        },
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="statistic-block" v-if="totalRepair">
        <div class="df df-space-between">
            <div class="text-size-xxl text-bold df">
                <span class="mr8">Ремонты</span>
            </div>
            <tumbler :options="options" :elem-width="101" v-model="form.option.value"/>
            <div class="text-size-xxl text-bold">
                {{totalRepair.repair}}
            </div>
        </div>
        <div class="graph graph-repairs">
            <div class="graph-item">
                <div class="graph-item__name">
                    Отложены
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{totalRepair.aside}}</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Ждём звонка / Думают
                </div>
                <div class="graph-item__value">
                    <div style="width: 86px" class="text-left"><b>{{ totalRepair.new }}</b> / {{ totalRepair.newDays }} дн.</div>
                    <div style="width: 86px;" class="text-right ml16"><b>{{ totalRepair.think }}</b> / {{ totalRepair.thinkDays }} дн.</div>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Предоплата
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalRepair.prepay }}</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Отказ
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalRepair.cancel }}</span>
                    <span>{{ totalRepair.cancelPercent }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    В работе
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalRepair.work }}</span>
                    <span>{{ totalRepair.workPercent }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Закачик отказался
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalRepair.cancelClient }}</span>
                    <span>{{ totalRepair.cancelClientPercent }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Мастер отказался
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalRepair.cancelMaster }}</span>
                    <span>{{ totalRepair.cancelMasterPercent }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Расторгнуты
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalRepair.terminate }}</span>
                    <span>{{ totalRepair.terminatePercent }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Завершены
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalRepair.finish }}</span>
                    <span>{{ totalRepair.finishPercent }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Tumbler from '@/components/Tumbler.vue';

export default {
    name: "Repair",
    components: {
        Tooltip,
        Tumbler,
    },
    data: function(){
        return {
            form: {
                option: {
                    value: {id: 0, name: 'Все'},
                    error: false,
                    repeatError: false,
                },
            },
        }
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            masters: state => state.statistic.masters,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
        }),
        options(){
            let masters = [];
            masters.push({id: 0, name: 'Все'})
            for(let m in this.masters.items){
                masters.push({id: this.masters.items[m].id, name: this.masters.items[m].name})
            }
            return masters
        },
        totalRepair(){
            let result = {};
            if(this.form.option.value.id === 0){
                result = this.masters.total;
            } else {
                for(let m in this.masters.items){
                    if(this.masters.items[m].id == this.form.option.value.id){
                        result = this.masters.items[m];
                    }
                }
            }

            return result
        },
    },
}
</script>

<style scoped>

</style>